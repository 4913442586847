
// ====> FOR ExtraHours:
// ORDINARY_HOURS --> +earnings +earningsTax +earningsSuper
// OVERTIME_EXEMPT_FROM_SUPER --> +earnings +earningsTax +earningsSuper=0

// ====> FOR Deduction:
// PRE_TAX_DEDUCTION --> -earnings -earningsTax -earningsSuper=0
// POST_TAX_DEDUCTION --> -earnings -earningsTax=0 -earningsSuper=0

// =====> FOR Allowance:
// SUBJECT_TO_TAX_AND_SUPER --> +earnings +earningsTax +earningsSuper
// SUBJECT_TO_TAX_EXEMPT_FROM_SUPER --> +earnings +earningsTax +earningsSuper=0
// EXEMPT_FROM_TAX_AND_SUPER --> +earnings +earningsTax=0 +earningsSuper=0

// =====> FOR Bonus:
// SUBJECT_TO_SUPER --> +earnings +earningsTax +earningsSuper
// EXEMPT_TO_SUPER --> +earnings +earningsTax=0 +earningsSuper=0

// ====> FOR Termination:
// netpay += (UnusedLeave * AmountTermination)

/* For Payroller */

// set Overrite Tax
function setOverriteTax(listReviewPayroll) {
    if (listReviewPayroll != undefined && listReviewPayroll.length > 0) {
        for (var i = 0; i < listReviewPayroll.length; i++) {
            var element = listReviewPayroll[i];
            if (element.temporaryTax != undefined) {
                if (element.overiteTax != 0 && element.overiteTax != undefined) {
                    element.overiteTax = element.temporaryTax
                }
            }
        }
    }
}

// getEarnings
function getEarnings(employees, listExtraHours, listBonous, listAllowance, listTermination) {
    var earnings = employees.ordinaryWorkHours * employees.ordinaryPayRate

    if (listExtraHours.length > 0) {
        for (var i = 0; i < listExtraHours.length; i++) {
            earnings = earnings + listExtraHours[i].hour * listExtraHours[i].rate
        }
    }

    if (listBonous.length > 0) {
        for (var j = 0; j < listBonous.length; j++) {
            earnings = earnings + listBonous[j].amountBonus
        }
    }

    if (listAllowance.length > 0) {
        for (var x = 0; x < listAllowance.length; x++) {
            earnings = earnings + listAllowance[x].unit * listAllowance[x].cost
        }
    }

    earnings = earnings + getTerminationPay(listTermination)

    if (employees.annualLeaveLoading != undefined && employees.annualLeaveLoading > 0) {
        earnings = earnings + employees.annualLeaveLoading
    }

    return earnings
}

// getEarningsTax
function getEarningsTax(employees, listExtraHours, listBonous, listAllowance) {
    var earnings = employees.ordinaryWorkHours * employees.ordinaryPayRate

    if (listExtraHours.length > 0) {
        for (var i = 0; i < listExtraHours.length; i++) {
            earnings = earnings + listExtraHours[i].hour * listExtraHours[i].rate
        }
    }

    if (listBonous.length > 0) {
        for (var j = 0; j < listBonous.length; j++) {
            if (listBonous[j].option != 'EXEMPT_TO_SUPER') {
                earnings = earnings + listBonous[j].amountBonus
            }
        }
    }

    if (listAllowance.length > 0) {
        for (var x = 0; x < listAllowance.length; x++) {
            if (listAllowance[x].option != 'EXEMPT_FROM_TAX_AND_SUPER') {
                earnings = earnings + listAllowance[x].unit * listAllowance[x].cost
            }
        }
    }

    if (employees.annualLeaveLoading != undefined && employees.annualLeaveLoading > 0) {
        earnings = earnings + employees.annualLeaveLoading
    }

    return earnings
}

// getEarningsSuper
function getEarningsSuper(employees, listExtraHours, listBonous, listAllowance) {
    var earnings = employees.ordinaryWorkHours * employees.ordinaryPayRate

    if (listExtraHours.length > 0) {
        for (var i = 0; i < listExtraHours.length; i++) {
            if (listExtraHours[i].option != 'OVERTIME_EXEMPT_FROM_SUPER') {
                earnings = earnings + listExtraHours[i].hour * listExtraHours[i].rate
            }
        }
    }

    if (listBonous.length > 0) {
        for (var j = 0; j < listBonous.length; j++) {
            if (listBonous[j].option != 'EXEMPT_TO_SUPER') {
                earnings = earnings + listBonous[j].amountBonus
            }
        }
    }

    if (listAllowance.length > 0) {
        for (var x = 0; x < listAllowance.length; x++) {
            if (listAllowance[x].option != 'SUBJECT_TO_TAX_EXEMPT_FROM_SUPER' && listAllowance[x].option != 'EXEMPT_FROM_TAX_AND_SUPER') {
                earnings = earnings + listAllowance[x].unit * listAllowance[x].cost
            }
        }
    }

    if (employees.annualLeaveLoading != undefined && employees.annualLeaveLoading > 0) {
        earnings = earnings + employees.annualLeaveLoading
    }

    return earnings
}

// getSuper
function getSuper(employees, listExtraHours, listBonous, listAllowance) {
    var earnings = employees.ordinaryWorkHours * employees.ordinaryPayRate

    if (listExtraHours.length > 0) {
        for (var i = 0; i < listExtraHours.length; i++) {
            if (listExtraHours[i].option == 'ORDINARY_HOURS') {
                earnings = earnings + listExtraHours[i].hour * listExtraHours[i].rate
            }
        }
    }

    if (listBonous.length > 0) {
        for (var j = 0; j < listBonous.length; j++) {
            if (listBonous[j].type == 'SUBJECT_TO_SUPER') {
                earnings = earnings + listBonous[j].amountBonus
            }
        }
    }

    if (listAllowance.length > 0) {
        for (var x = 0; x < listAllowance.length; x++) {
            if (listAllowance[x].option == 'SUBJECT_TO_TAX_AND_SUPER') {
                earnings = earnings + listAllowance[x].unit * listAllowance[x].cost
            }
        }
    }

    var countSuper = (employees.contributionRate * earnings) / 100
    // if(employees.employeeData != undefined) {
    //     countSuper += employees.employeeData.superSalarySacrifice +
    //     employees.employeeData.employerContribution
    // }
    return countSuper
}

/* For Review */
function getTotalTax(listReviewPayroll) {
    if (listReviewPayroll != undefined && listReviewPayroll.length > 0) {
        var totalTax = 0
        for (var i = 0; i < listReviewPayroll.length; i++) {
            if (listReviewPayroll[i].activePayroll == 'ACTIVE') {
                if (listReviewPayroll[i].temporaryTax != undefined) {
                    totalTax = totalTax + listReviewPayroll[i].temporaryTax
                } else if (listReviewPayroll[i].overiteTax != 0 && listReviewPayroll[i].overiteTax != undefined) {
                    totalTax = totalTax + listReviewPayroll[i].overiteTax
                } else {
                    totalTax = totalTax + listReviewPayroll[i].tax
                }
            }
        }
        return totalTax
    }
}

function getNetPay(reviewPayroll) {
    //  console.log(reviewPayroll.netPay);
    if (reviewPayroll != undefined) {
        var netPay = 0
        if (reviewPayroll.activePayroll == 'ACTIVE') {
            if (reviewPayroll.overiteTax != 0 && reviewPayroll.overiteTax != undefined) {
                netPay = reviewPayroll.netPay - (reviewPayroll.overiteTax - reviewPayroll.oldTax)
            } else {
                netPay = reviewPayroll.netPay
            }
        }
        return netPay
    }
}

function getTotalNetPay(listReviewPayroll) {
    if (listReviewPayroll != undefined && listReviewPayroll.length > 0) {
        var netPay = 0
        for (var i = 0; i < listReviewPayroll.length; i++) {
            if (listReviewPayroll[i].activePayroll == 'ACTIVE') {
                netPay = netPay + this.getNetPay(listReviewPayroll[i])
            }
        }
        return netPay
    }
}

function getTotalAmountPaid(listReviewPayroll) {
    if (listReviewPayroll != undefined && listReviewPayroll.length > 0) {
        var amountPaid = 0
        for (var i = 0; i < listReviewPayroll.length; i++) {
            if (listReviewPayroll[i].activePayroll == 'ACTIVE') {
                amountPaid = amountPaid + getamountPaid(listReviewPayroll[i])
            }
        }
        return amountPaid
    }
}

function getamountPaid(reviewPayroll) {
    if (reviewPayroll != undefined) {
        var amountPaid = 0
        if (reviewPayroll.activePayroll == 'ACTIVE') {
            if (reviewPayroll.overiteTax != 0 && reviewPayroll.overiteTax != undefined) {
                amountPaid = reviewPayroll.amountPaid - (reviewPayroll.overiteTax - reviewPayroll.oldTax)
            } else {
                amountPaid = reviewPayroll.amountPaid
            }
        }
        return amountPaid
    }
}

function getDeduction(reviewPayroll) {
    // console.log("injinrtjg");
    if (reviewPayroll != undefined) {
        var deduction = 0
        if (reviewPayroll.activePayroll == 'ACTIVE') {
            if (reviewPayroll.listDeduction != undefined && reviewPayroll.listDeduction.length > 0) {
                for (var j = 0; j < reviewPayroll.listDeduction.length; j++) {
                    if (reviewPayroll.listDeduction[j] != undefined) {
                        deduction = deduction + reviewPayroll.listDeduction[j].amountDeduction
                    }
                } 
            }
                deduction = deduction + reviewPayroll.employeeData.superSalarySacrifice
           
        }
        return deduction
    }
}

function getTotalDeduction(listReviewPayroll) {
    if (listReviewPayroll != undefined && listReviewPayroll.length > 0) {
        var deduction = 0
        for (var i = 0; i < listReviewPayroll.length; i++) {
            if (listReviewPayroll[i].activePayroll == 'ACTIVE') {
                deduction = deduction + getDeduction(listReviewPayroll[i])
            }
        }
        return deduction
    }
}

function getAllowance(reviewPayroll) {
    if (reviewPayroll != undefined) {
        var allowance = 0
        if (reviewPayroll.activePayroll == 'ACTIVE') {
            if (reviewPayroll.listAllowance != undefined && reviewPayroll.listAllowance.length > 0) {
                for (var j = 0; j < reviewPayroll.listAllowance.length; j++) {
                    allowance = allowance + (reviewPayroll.listAllowance[j].unit * reviewPayroll.listAllowance[j].cost)
                }
            }
        }
        return allowance
    }
}

function getTotalAllowance(listReviewPayroll) {
    if (listReviewPayroll != undefined && listReviewPayroll.length > 0) {
        var allowance = 0
        for (var i = 0; i < listReviewPayroll.length; i++) {
            if (listReviewPayroll[i].activePayroll == 'ACTIVE') {
                allowance = allowance + getAllowance(listReviewPayroll[i])
            }
        }
        return allowance
    }
}

function getTerminationPay(listTermination) {
    var terminationPay = 0;
    listTermination.forEach(element => {
        if (element.type == 'GENUINE') {
            terminationPay += (element.unusedLeave * element.amountTermination) + Number(element.redundancy) + Number(element.etpType)
        } else {
            terminationPay += (element.unusedLeave * element.amountTermination) + Number(element.etpType)
        }
    });
    return terminationPay
}

// function getTaxTerminationPay(listTermination) {
//     var terminationPay = 0;
//     listTermination.forEach(element => {
//         if(element.type == 'GENUINE') {
//             terminationPay += (element.unusedLeave * element.amountTermination) + element.redundancy + element.etpType
//         } else {
//             terminationPay += (element.unusedLeave * element.amountTermination) + element.etpType
//         }
//     });
//     return terminationPay
// }

export {
    setOverriteTax,
    getEarnings,
    getEarningsTax,
    getEarningsSuper,
    getSuper,
    getTotalTax,
    getNetPay,
    getTotalNetPay,
    getTotalAmountPaid,
    getamountPaid,
    getDeduction,
    getTotalDeduction,
    getAllowance,
    getTotalAllowance,
    getTerminationPay,
    // getTaxTerminationPay
}